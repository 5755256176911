import 'angular-ui-grid/ui-grid.min.css';

import jQuery from 'jquery';
import window from 'window';
window.$ = jQuery;
window.jQuery = jQuery;

import 'jquery-ui/ui/focusable';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/slider';

import angular from '@splunk/olly-angular';
window.angular = angular;

import mustache from 'mustache';
window.Mustache = mustache;

import handlebars from 'handlebars';
window.Handlebars = handlebars;

import tether from 'tether';
window.Tether = tether;

import drop from 'tether-drop';
window.Drop = drop;

import tooltip from 'tether-tooltip';
window.Tooltip = tooltip;

import lodash from 'lodash';
window._ = lodash;

import moment from 'moment';
window.moment = moment;
import 'moment-timezone';

import restangular from 'restangular';
window.Restangular = restangular;

import 'swagger-angular-client/dist/swagger-angular-client.min';
import '@splunk/olly-angular-gridster/dist/angular-gridster.min';
import '@splunk/olly-angular-gridster/dist/angular-gridster.min.css';
import 'angular-ui-utils/modules/utils';
import 'angular-ui-utils/modules/event/event';
import 'angular-ui-utils/modules/format/format';
import 'angular-ui-utils/modules/highlight/highlight';
import 'angular-ui-utils/modules/include/include';
import 'angular-ui-utils/modules/indeterminate/indeterminate';
import 'angular-ui-utils/modules/alias/alias';
import 'angular-ui-utils/modules/inflector/inflector';
import 'angular-ui-utils/modules/keypress/keypress';
import 'angular-ui-utils/modules/jq/jq';
import 'angular-ui-utils/modules/reset/reset';
import 'angular-ui-utils/modules/scroll/scroll';
import 'angular-ui-utils/modules/scroll/scroll-jqlite';
import 'angular-ui-utils/modules/scrollfix/scrollfix';
import 'angular-ui-utils/modules/mask/mask';
import 'angular-ui-utils/modules/validate/validate';
import 'angular-ui-utils/modules/route/route';
import 'angular-ui-utils/modules/ie-shiv/ie-shiv';
import 'angular-ui-utils/modules/showhide/showhide';
import 'angular-ui-utils/modules/unique/unique';

import html2canvas from 'html2canvas';
window.html2canvas = html2canvas;
